import $ from 'jquery';
window.jQuery = $;
require('slick-carousel');

export default {
  init() {
    // JavaScript to be fired on all pages

    // Donate Takeover
    $('a[href="#donate"]').click(e => {
      e.preventDefault();
      const donateLink = $('body').data('donate');
      window.open(donateLink, '_blank');
    });

    // Navigation Toggle
    $('#nav-toggle').click(e => {
      e.preventDefault();
      $(e.currentTarget).toggleClass('is-active');
      $('#nav-primary').toggleClass('is-active');
    });

    // Image carousel block
    $('.block-images .slider').slick({
      arrows: false
    });

    // Testimonials Carousel
    $('.testimonials.carousel').slick({
      adaptiveHeight: true,
      fade: true
    });

    // Grantees Carousel
    $('.grantees.carousel').slick({
      arrows: false,
      dots: true,
      mobileFirst: true,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4
            }
          }
        ]
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after init JS is fired

    // Add class to .header when scrolled one pixel from the top
    window.addEventListener('scroll', () => {
      const scrolled = window.scrollY;
      const header = document.querySelector('header');

      if (scrolled > 0) {
        header.classList.add('is-scroll');
      } else {
        header.classList.remove('is-scroll');
      }
    });

    // Parallax scrolling
    const parallaxElements = document.querySelectorAll('.parallax');

    parallaxElements.forEach((element) => {
      const img = element;
      const imgParent = element.parentNode;

      const parallaxImg = () => {
        const speed = img.dataset.speed;
        const imgY = imgParent.offsetTop;
        const winY = window.scrollY;
        const winH = window.innerHeight;
        const parentH = imgParent.clientHeight;
        const winBottom = winY + winH;

        if (winBottom > imgY && winY < imgY + parentH) {
          const imgBottom = (winBottom - imgY) * speed;
          const imgTop = winH + parentH;
          const imgPercent = (imgBottom / imgTop) * 100 + (50 - speed * 50);

          img.style.top = `${imgPercent}%`;
          img.style.transform = `translate(-50%, -${imgPercent}%)`;
        }
      };

      document.addEventListener('scroll', parallaxImg);
      document.addEventListener('DOMContentLoaded', parallaxImg);

      window.addEventListener('load', () => {
        parallaxImg();
        img.classList.add('is-loaded');
      });
    });

    // Member Modal
    $('.staff .member').click(e => {
      $('#modal').addClass('is-active');
      const memberContent = $(e.currentTarget).clone();
      $('#modal-data').append(memberContent);
    });

    // Modal Close
    $('#modal-underlay, #modal-close').click(e => {
      $('#modal').removeClass('is-active');
      $('#modal-data').html('');
    });
  },
};
